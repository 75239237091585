exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[blog]-details-blog-js": () => import("./../../../src/pages/blog-details/[blog].js" /* webpackChunkName: "component---src-pages-[blog]-details-blog-js" */),
  "component---src-pages-[car]-details-car-js": () => import("./../../../src/pages/car-details/[car].js" /* webpackChunkName: "component---src-pages-[car]-details-car-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancellation-and-damage-js": () => import("./../../../src/pages/cancellation-and-damage.js" /* webpackChunkName: "component---src-pages-cancellation-and-damage-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tariffs-js": () => import("./../../../src/pages/tariffs.js" /* webpackChunkName: "component---src-pages-tariffs-js" */),
  "component---src-pages-whyus-js": () => import("./../../../src/pages/whyus.js" /* webpackChunkName: "component---src-pages-whyus-js" */)
}

